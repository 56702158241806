import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMobile, faKey } from "@fortawesome/free-solid-svg-icons";
import { AuthenticationMethodProps } from "../models/types";
import { Card, Col, Row } from "react-bootstrap";

export default function AuthenticationMethod({
                                               authMethod,
  setSelectedAuthMethod,
}: AuthenticationMethodProps) {
  const { description } = authMethod;
  const type = authMethod.authenticationType;
  const icon =
    type === "sms" ? (
      <FontAwesomeIcon icon={faMobile} className="auth-method-icon" />
    ) : type === "email" ? (
      <FontAwesomeIcon icon={faEnvelope} className="auth-method-icon" />
    ) : (
      <FontAwesomeIcon icon={faKey} className="auth-method-icon" />
    );

  const title =
    type === 'sms' ? 'SMS' : type === 'email' ? description ?? 'E-mail' : 'Access key';

  const text =
    type === "sms"
      ? "Receive code via SMS"
      : type === "email"
      ? "Receive code via e-mail"
      : "Sign in using an access key";

  return (
    <Card
      className="mb-2 dark-on-hover"
      onClick={() => setSelectedAuthMethod(authMethod)}
    >
      <div className="card-body d-flex align-items-center">
        <div>{icon}</div>
        <div>
          <div className="text-14">
            <strong>{title}</strong>
          </div>
          <div>{text}</div>
        </div>
      </div>
    </Card>
  );
}
