import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import ErrorScreen from "./components/ErrorScreen";
import { ErrorBoundary } from "react-error-boundary";

const router = createBrowserRouter([
  {
    path: "/:travelId",
    element: (
      <ErrorBoundary fallback={<ErrorScreen />}>
        <App />
      </ErrorBoundary>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: "/",
    loader: async () => {
      // Check the backend if any travel ID exists for our cookie (if any)
      const e = await fetch(`/api/v1/travel`);
      const response = await e.json();

      if (response.travelId) {
        return redirect(`/${response.travelId}`);
      } else {
        // No travel returned by the backend
        throw new Error();
      }
    },
    element: <></>,
    errorElement: <ErrorScreen />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
