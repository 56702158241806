import { Card, Table } from "react-bootstrap";
import { EssentialInformationProps } from "../models/types";

export default function EssentialInformation({
  travelInformation,
}: EssentialInformationProps) {

  const phoneHref = 'tel:' + travelInformation?.emergencyPhone;
  const mailHref = 'mailto:' + travelInformation?.emergencyEmail;

  return (
    <Card>
      <Table borderless className="essential-information-table">
        <tbody>
          <tr>
            <td>Period</td>
            <td>
              <strong>{travelInformation?.period}</strong>
            </td>
          </tr>
          <tr>
            <td>Emergency Contact</td>
            <td>
              <strong>
                <a
                  href={phoneHref}
                  className="black-text no-text-decoration underline-on-hover"
                >
                  {travelInformation?.emergencyPhone}
                </a>
              </strong>
            </td>
          </tr>
          <tr>
            <td>Emergency E-mail</td>
            <td>
              <strong>
                <a
                  href={mailHref}
                  className="black-text no-text-decoration underline-on-hover"
                >
                  {travelInformation?.emergencyEmail}
                </a>
              </strong>
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}
