import {
  Card,
  Col,
  Placeholder,
  PlaceholderButton,
  Row,
  Table,
} from "react-bootstrap";

export default function PlaceholderFileInfo() {
  return (
    <>
      <Row xs={8}>
        <Col className="d-flex justify-content-center mt-5 mb-4">
          <strong>YOUR TRAVEL DOCUMENTS</strong>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="travel-documents">
            <Table striped borderless>
              <thead>
                <tr>
                  <th className="file-icon-column"></th>
                  <th className="filename-column">FILENAME</th>
                  <th className="d-none d-md-table-cell">LAST UPDATE</th>
                  <th className="d-none d-md-table-cell">SIZE</th>
                  <th>DOWNLOAD</th>
                </tr>
              </thead>
              <tbody>
                <tr className="align-middle">
                  <td></td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={4} />
                    </Placeholder>

                    {/* Shown only on screen sizes md and smaller */}
                    <div className="d-block d-md-none">
                      <Placeholder animation="glow">
                        <Placeholder xs={6} />
                      </Placeholder>
                    </div>
                  </td>

                  {/* Hidden on screen sizes md and smaller */}
                  <td className="d-none d-md-table-cell">
                    <Placeholder animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                  </td>
                  {/* Hidden on screen sizes md and smaller */}
                  <td className="d-none d-md-table-cell">
                    <Placeholder animation="glow">
                      <Placeholder xs={4} />
                    </Placeholder>
                  </td>
                  <td>
                    <Placeholder animation="glow">
                      <PlaceholderButton xs={5} variant="secondary" />
                    </Placeholder>
                  </td>
                </tr>
                <tr className="align-middle">
                  <td></td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={3} />
                    </Placeholder>

                    {/* Shown only on screen sizes md and smaller */}
                    <div className="d-block d-md-none">
                      <Placeholder animation="glow">
                        <Placeholder xs={6} />
                      </Placeholder>
                    </div>
                  </td>

                  {/* Hidden on screen sizes md and smaller */}
                  <td className="d-none d-md-table-cell">
                    <Placeholder animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                  </td>
                  {/* Hidden on screen sizes md and smaller */}
                  <td className="d-none d-md-table-cell">
                    <Placeholder animation="glow">
                      <Placeholder xs={5} />
                    </Placeholder>
                  </td>
                  <td>
                    <Placeholder animation="glow">
                      <PlaceholderButton xs={5} variant="secondary" />
                    </Placeholder>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>

      <Row xs={8}>
        <Placeholder animation="glow">
          <Col className="d-flex flex-row-reverse mt-4">
            <Placeholder xs={3} />
          </Col>
        </Placeholder>
      </Row>
    </>
  );
}
