import { Card, Col, Placeholder, Row, Table } from "react-bootstrap";

export default function PlaceholderEssentialInformation() {
  return (
    <>
      <Row className="d-flex align-items-center" xs={8}>
        <Col xs={3}>
          <img src="gct_logo.png" alt="Najtura logo" className="img-fluid" />
        </Col>
        <Col>
          <p className="h2">
            <Placeholder animation="glow">
              <Placeholder xs={7} />
            </Placeholder>
          </p>
          <p className="h3">
            <Placeholder animation="glow">
              <Placeholder xs={5} />
            </Placeholder>
          </p>
        </Col>
      </Row>
      <Row xs={8}>
        <Col className="d-flex justify-content-center mb-4">
          <strong>ESSENTIAL INFORMATION</strong>
        </Col>
      </Row>
      <Row xs={8}>
        <Col>
          <Card>
            <Table borderless className="essential-information-table">
              <tbody>
                <tr>
                  <td>Client</td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={9} />
                    </Placeholder>
                  </td>
                </tr>
                <tr>
                  <td>Period</td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={7} />
                    </Placeholder>
                  </td>
                </tr>
                <tr>
                  <td>Emergency Contact</td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={8} />
                    </Placeholder>
                  </td>
                </tr>
                <tr>
                  <td>Emergency E-mail</td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
}
