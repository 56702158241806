import { Col, Row } from "react-bootstrap";

export default function ErrorScreen() {
  return (
    <>
      <Row className="justify-content-center mb-4">
        <Col md={7}>
          <img
            src="gct_logo.png"
            alt="Najtura logo"
            className="img-fluid auth-logo"
          />
        </Col>
      </Row>
      <Row className="justify-content-center mb-4">
        <Col md={7}>
          <div className='entry-form'>
            <div className='error-text'>There was an error fetching Tour with the ID you provided.</div>
            <div className='error-text'>Please talk to your operator or contact us through our website</div>
            <div className='error-text'><a
              target='_blank' rel='noopener noreferrer' href='https://www.grandcuveetours.com/contact/'>www.grandcuveetours.com</a>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
