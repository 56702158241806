import { Badge, Button, Card, Table } from "react-bootstrap";
import {
  faFile,
  faFilePdf,
  faFileWord,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import prettyBytes from "pretty-bytes";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { TravelDocumentsProps } from "../models/types";

const createBadge = (status: string | undefined) => {
  if (status === undefined) {
    return <></>;
  }

  switch (status.toLowerCase()) {
    case "new":
      return (
        <Badge pill bg="primary">
          NEW
        </Badge>
      );

    case "updated":
      return (
        <Badge pill bg="secondary">
          UPDATED
        </Badge>
      );

    default:
      return <></>;
  }
};

const createFileIcon = (filename: string) => {
  const extension = filename.split(".").slice(-1)[0];

  switch (extension.toLowerCase()) {
    case "pdf":
      return <FontAwesomeIcon icon={faFilePdf} className="file-icon" />;

    case "docx":
      return <FontAwesomeIcon icon={faFileWord} className="file-icon" />;

    default:
      return <FontAwesomeIcon icon={faFile} className="file-icon" />;
  }
};

const timestampToFormatted = (timestamp: string) => {
  return format(new Date(timestamp), "MMM d yyyy H:mm");
};

export default function TravelDocuments({ files }: TravelDocumentsProps) {
  return (
    <Card className="travel-documents">
      <Table striped borderless>
        <thead>
          <tr>
            <th className="file-icon-column"></th>
            <th className="filename-column">FILENAME</th>
            <th className="d-none d-md-table-cell">LAST UPDATE</th>
            <th className="d-none d-md-table-cell">SIZE</th>
            <th>DOWNLOAD</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file) => (
            <tr key={file.id} className="align-middle">
              <td>{createFileIcon(file.filename)}</td>
              <td className="text-break">
                <strong>
                  <a
                    href={file.downloadUrl}
                    className="black-text no-text-decoration underline-on-hover"
                  >
                    {file.filename}
                  </a>{" "}
                  {createBadge(file.status)}
                </strong>

                {/* Shown only on screen sizes md and smaller */}
                <div className="d-block d-md-none">
                  <strong>UPDATED</strong>{" "}
                  {timestampToFormatted(file.modifiedDate)}
                </div>
              </td>

              {/* Hidden on screen sizes md and smaller */}
              <td className="d-none d-md-table-cell">
                {timestampToFormatted(file.modifiedDate)}
              </td>
              {/* Hidden on screen sizes md and smaller */}
              <td className="d-none d-md-table-cell">
                {prettyBytes(file.size)}
              </td>
              <td>
                <Button
                  onClick={() => window.open(file.downloadUrl)}
                  className="rounded-pill download-button"
                >
                  <FontAwesomeIcon icon={faArrowDown} className="" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
}
