import { Col, Row } from "react-bootstrap";
import PlaceholderAuthenticationMethod from "./PlaceholderAuthenticationMethod";

export default function PlaceholderAuthenticationMethodList() {
  return (
    <>
      <Row className="justify-content-center mb-1">
        <Col md={3}>
          <PlaceholderAuthenticationMethod />
        </Col>
      </Row>
      <Row className="justify-content-center mb-1">
        <Col md={3}>
          <PlaceholderAuthenticationMethod />
        </Col>
      </Row>
      <Row className="justify-content-center mb-1">
        <Col md={3}>
          <PlaceholderAuthenticationMethod />
        </Col>
      </Row>
    </>
  );
}
