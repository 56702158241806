import { useState, Suspense } from "react";
import { AuthenticationType, AuthenticationScreenProps, AuthMethod } from '../models/types';
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import AuthenticationMethodList from "./AuthenticationMethodList";
import PlaceholderAuthenticationMethodList from "./placeholders/PlaceholderAuthenticationMethodList";
import { useParams } from 'react-router-dom';

export default function AuthenticationScreen({
  setScreenMode,
}: AuthenticationScreenProps) {
  const [selectedAuthMethod, setSelectedAuthMethod] = useState<
    AuthMethod | undefined
  >();
  const [OTPValue, setOTPValue] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [isIncorrectOPT, setIsIncorrectOTP] = useState(false);

  let { travelId } = useParams();

  const selectAuthenticationMethod = (authMethod: AuthMethod | undefined) => {
    setSelectedAuthMethod(authMethod);

    if (authMethod === undefined || authMethod.authenticationType === 'password') {
      return;
    }

    fetch(`/api/v1/travel/${travelId}/auth/${authMethod.authenticationType}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uuid: authMethod.uuid }),
    }).then((r) => {
      if (!r.ok) {
        throw new Error();
      }
    });
  };

  const sendOTP = (event: React.FormEvent) => {
    event.preventDefault(); // Don't reload page

    fetch(`/api/v1/travel/${travelId}/auth`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        code: OTPValue,
        permanent: rememberMe,
      }),
    }).then((r) => {
      if (r.status === 401) {
        setIsIncorrectOTP(true);
        return;
      }

      if (!r.ok) {
        throw new Error(r.statusText);
      }

      // Set auth finished
      setScreenMode("fileList");
    });
  };

  const getAuthenticationView = () => {
    if (selectedAuthMethod === undefined) {
      return (
        <>
          <Row className="justify-content-center mb-3">
            <Col md={3} className="min-width-300">
              Select one of the options below to confirm your identity:
            </Col>
          </Row>

          <Suspense fallback={<PlaceholderAuthenticationMethodList />}>
            <AuthenticationMethodList
              setSelectedAuthMethod={selectAuthenticationMethod}
            />
          </Suspense>
        </>
      );
    }

    return (
      <>
        <Row className="justify-content-center">
          <Col md={3} className="min-width-300">
            <Button
              onClick={() => setSelectedAuthMethod(undefined)}
              variant="link"
              className="no-text-decoration ps-0 text-12 grey-text"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="back-to-auth-arrow" />
              Choose a different verification method.
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center mb-3">
          <Col md={3} className="min-width-300">
            Enter your 6 character login code received through your selected
            authentication option:
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={3} className="min-width-300">
            <Form onSubmit={sendOTP}>
              {isIncorrectOPT && (
                <Alert variant="warning">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="me-2"
                  />
                  Incorrect OTP entered! Please try again.
                </Alert>
              )}
              <div className="token-input-wrapper">
                <Form.Control
                  type="password"
                  required
                  pattern="\d{6}"
                  placeholder="Enter code"
                  className="token-input text-12"
                  value={OTPValue}
                  onChange={(e) => setOTPValue(e.target.value)}
                />
                <Button className="token-input-button" type="submit">
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
              <Form.Check // prettier-ignore
                type="checkbox"
                className="mt-4"
                id="remember-me-checkbox"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
                label={
                  <>
                    <strong>Remember me</strong>
                    <br />
                    <span className="grey-text">
                      This functionality uses session cookies to store your
                      session.
                    </span>
                  </>
                }
              />
            </Form>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div className="text-12">
      <Row className="justify-content-center mb-4">
        <Col md={3}>
          <img
            src="gct_logo.png"
            alt="Najtura logo"
            className="img-fluid auth-logo"
          />
        </Col>
      </Row>
      {getAuthenticationView()}
    </div>
  );
}
