import { useEffect, useState } from "react";
import AuthenticationScreen from "./components/AuthenticationScreen";
import FileListWidget from "./components/FileListScreen";
import { Container } from "react-bootstrap";
import { ScreenModes } from "./models/types";
import { useParams } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  const [screenMode, setScreenMode] = useState<ScreenModes>("loading");

  let { travelId } = useParams();

  // Check if the user is logged in by sending a request
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    fetch(`/api/v1/travel/${travelId}`, {
      signal,
    })
      .then((e) => {
        if (e.ok) {
          setScreenMode("fileList");
        } else {
          setScreenMode("authRequired");
        }
      })
      .catch((err) => {
        if (!signal.aborted) {
          // Show error at the error boundary
          throw new Error(err);
        }
      });

    return function cleanup() {
      // Cancel the request when the component unmounts
      abortController.abort();
    };
  }, [travelId]);

  const getScreen = () => {
    if (screenMode === "loading") {
      //TODO: Just empty for now, maybe use something else later.
      return <></>;
    }

    if (screenMode === "authRequired") {
      return <AuthenticationScreen setScreenMode={setScreenMode} />;
    }

    return <FileListWidget />;
  };

  return (
    <>
      <Container className="main-container mt-2">{getScreen()}</Container>
      <div className="footer">
        Copyright © 2024 Grand Cuvée Tours, Naj Tura d.o.o.
      </div>
    </>
  );
}

export default App;
