import { Card, Col, Placeholder, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

export default function PlaceholderAuthenticationMethod() {
  return (
    <Card className="mb-2">
      <Row className="card-body d-flex align-items-center">
        <FontAwesomeIcon
          icon={faSquare}
          className="col-2 auth-method-icon grey-text"
        />
        <Col>
          <div>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={4} />
            </Placeholder>
          </div>
          <div>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={9} />
            </Placeholder>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
