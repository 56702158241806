import { Suspense } from "react";
import PlaceholderEssentialInformation from "./placeholders/PlaceholderEssentialInformation";
import PlaceholderFileInfo from "./placeholders/PlaceholderFileInfo";
import FilesInfo from "./FilesInfo";
import TravelInfo from "./TravelInfo";

export default function FileListWidget() {
  return (
    <div className="file-list-widget">
      <Suspense fallback={<PlaceholderEssentialInformation />}>
        <TravelInfo />
      </Suspense>

      <Suspense fallback={<PlaceholderFileInfo />}>
        <FilesInfo />
      </Suspense>
    </div>
  );
}
