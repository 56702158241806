import { Col, Row } from "react-bootstrap";
import AuthenticationMethod from "./AuthenticationMethod";
import {
  AuthenticationMethodListProps,
  AuthenticationType, TravelAuthMethods,
} from '../models/types';
import useSWR from "swr";
import { fetcherGet } from "../fetcher";
import { useParams } from "react-router-dom";

export default function AuthenticationMethodList({
  setSelectedAuthMethod,
}: AuthenticationMethodListProps) {
  let { travelId } = useParams();

  const { data, error } = useSWR<TravelAuthMethods>(
    `/api/v1/travel/${travelId}/auth`,
    fetcherGet,
    { suspense: true }
  );

  if (error) {
    throw new Error(error);
  }

  return (
    <>
      {data?.authMethods.map((method) => {
        return (
          <Row className="justify-content-center mb-1" key={method.authenticationType + method.uuid}>
            <Col md={3} className="min-width-300">
              <AuthenticationMethod
                authMethod={method}
                setSelectedAuthMethod={setSelectedAuthMethod}
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
}
