import useSWR from "swr";
import { fetcherGet } from "../fetcher";
import { Button, Col, Row } from "react-bootstrap";
import { TravelFilesInformation } from "../models/types";
import TravelDocuments from "./TravelDocuments";
import prettyBytes from "pretty-bytes";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";

export default function FilesInfo() {
  let { travelId } = useParams();

  const { data, error } = useSWR<TravelFilesInformation>(
    `/api/v1/travel/${travelId}/file`,
    fetcherGet,
    { suspense: true }
  );

  if (data === undefined || error) {
    throw new Error(error);
  }

  return (
    <>
      <Row xs={8}>
        <Col className="d-flex justify-content-center mt-5 mb-4">
          <strong>YOUR TRAVEL DOCUMENTS</strong>
        </Col>
      </Row>
      <Row>
        <Col>
          <TravelDocuments files={data.files} />
        </Col>
      </Row>
        {/** TODO
      <Row className="flex-row-reverse mt-4" xs={8}>
        <div className="bottom-row align-middle">
          <strong>{prettyBytes(data.size)}</strong>{" "}
          <Button
            onClick={() => window.open(data.downloadAllUrl)}
            className="rounded-pill download-all-button ms-3"
          >
            DOWNLOAD ALL <FontAwesomeIcon icon={faArrowDown} className="" />
          </Button>
        </div>
      </Row>**/}
    </>
  );
}
