import useSWR from "swr";
import { fetcherGet } from "../fetcher";
import { Col, Row } from "react-bootstrap";
import { TravelInformation } from "../models/types";
import EssentialInformation from "./EssentialInformation";
import { useParams } from "react-router-dom";

export default function TravelInfo() {
  let { travelId } = useParams();

  const { data, error } = useSWR<TravelInformation>(
    `/api/v1/travel/${travelId}`,
    fetcherGet,
    { suspense: true }
  );

  if (data === undefined || error) {
    throw new Error(error);
  }

  return (
    <>
      <Row className="align-items-center" xs={8}>
        <Col xs={3}>
          <img src="gct_logo.png" alt="Najtura logo" className="img-fluid" />
        </Col>
        <Col>
          <p className="h2 italic">{data.title}</p>
          <p className="h3">{data.client}</p>
        </Col>
      </Row>
      <Row xs={8}>
        <Col className="d-flex justify-content-center mb-4">
          <strong>ESSENTIAL INFORMATION</strong>
        </Col>
      </Row>
      <Row xs={8}>
        <Col>
          <EssentialInformation travelInformation={data} />
        </Col>
      </Row>
    </>
  );
}
